import React from "react"
import Container from "@material-ui/core/Container"
import About from "./sections/about"
import Portfolio from "./sections/portfolio"
import homeStyles from "./home.module.scss"

const Home = () => (
  <div className={homeStyles.container}>
    <Container>
      <About />
      <Portfolio />
    </Container>
  </div>
)

export default Home
