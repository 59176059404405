import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/sections/hero"
import Home from "../components/home"

const IndexPage = () => (
  <Layout>
    <SEO title="Alexander Kalsey" />
    <Hero />
    <Home />
  </Layout>
)

export default IndexPage