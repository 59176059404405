import React from "react"
import aboutStyles from "./about.module.scss"

function About(props) {
  return (
    <div className="section">
      <h2>
        Hi, I'm Alex Kalsey. <br className="respbreak" /> I build and lead
        creative teams.
      </h2>
      <p className={aboutStyles.fullWidth}>
        Through 5 election cycles in Democratic politics as a Creative Director,
        video editor and motion graphics designer, I’ve contributed to three
        popular vote-winning presidential campaigns and dozens of senate,
        congressional and gubernatorial races.
      </p>
      <p className={aboutStyles.fullWidth}>
        Most recently, as Director of Creative Production at Biden for
        President, I helped build a first-of-its-kind remote production team. We
        grew from a scrappy crew of eight to a $600 million agency in a few
        months &mdash; during a pandemic.
      </p>
    </div>
  )
}

export default About
