import React from "react"
import heroStyles from "./hero.module.scss"
import heroVideo from "../../assets/video/heroloop.mp4"
import heroImage from "../../images/hero-fallback.jpg"

function Hero(props) {
  return (
    <div className={heroStyles.container}>
      <video className={heroStyles.video} autoPlay loop muted playsInline>
        <source src={heroVideo} type="video/mp4" />
        <img src={heroImage} alt="" />
      </video>
    </div>
  )
}

export default Hero
