import React, { useState } from "react"
import Grid from "@material-ui/core/Grid"
import Image from "./image.js"
import thumbnailStyles from "./thumbnail.module.scss"
import ModalVideo from "react-modal-video"

function Thumbnail(props) {
  const [isOpen, setOpen] = useState(false)

  return (
    <React.Fragment>
      <Grid item sm={6} md={4} className={thumbnailStyles.thumbnail}>
        <div
          className={thumbnailStyles.thumbnail_item}
          onClick={() => setOpen(true)}
          onKeyDown={() => setOpen(true)}
          role="button"
          tabindex="0"
        >
          <Image
            filename={props.image}
            type="thumbnail"
            alt={props.title}
            cssname={thumbnailStyles.thumbnail_image}
          />
          <div className={thumbnailStyles.thumbnail_overlay}>
            <h3 className={thumbnailStyles.thumbnail_title}>{props.title}</h3>
          </div>
        </div>
      </Grid>
      <ModalVideo
        channel="vimeo"
        vimeo={{ autoplay: 1, byline: 0, portrait: 0, title: 0 }}
        isOpen={isOpen}
        videoId={props.vimeo}
        onClose={() => setOpen(false)}
      />
    </React.Fragment>
  )
}

export default Thumbnail
