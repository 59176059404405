import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

export default props => {
  const {
    filename,
    type = "default",
    alt,
    sizes = "(max-width: 400px) 100px, (max-width: 800px) 200px, 400px",
    cssname,
  } = props

  const images = useStaticQuery(graphql`
    query ImageQuery {
      data: allFile {
        edges {
          node {
            relativePath
            default: childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
            square: childImageSharp {
              fluid(maxWidth: 600, maxHeight: 600) {
                ...GatsbyImageSharpFluid
              }
            }
            thumbnail: childImageSharp {
              fluid(maxWidth: 600, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const image = images.data.edges.find(n => {
    return n.node.relativePath.includes(filename)
  })

  if (!image) {
    return null
  }

  return (
    <Img
      className={cssname}
      alt={alt}
      fluid={{
        ...image.node[type].fluid,
        sizes: sizes,
      }}
    />
  )
}
