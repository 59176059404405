import React from "react"
import Grid from "@material-ui/core/Grid"
import Thumbnail from "../thumbnail.js"

function Portfolio(props) {
  return (
    <div className="section">
      <Grid container spacing={3}>
        <Thumbnail
          vimeo="480430100"
          image="portfolio--rising.jpg"
          title="Rising"
        />
        <Thumbnail
          vimeo="480940152"
          image="portfolio--thats-a-president.jpg"
          title="That's a President"
        />
        <Thumbnail
          vimeo="480449709"
          image="portfolio--tell-you.jpg"
          title="Tell You"
        />
        <Thumbnail
          vimeo="480467449"
          image="portfolio--made-in-america.jpg"
          title="Made in America"
        />
        <Thumbnail
          vimeo="489450390"
          image="portfolio--vp-announcement.jpg"
          title="Running Mate Annoucement"
        />
        <Thumbnail
          vimeo="480433669"
          image="portfolio--in-the-dark.jpg"
          title="In the Dark"
        />
      </Grid>
    </div>
  )
}

export default Portfolio
